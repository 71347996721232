
import { computed, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    active: { type: String as PropType<'data' | 'grading'>, default: 'data' },
  },
  setup() {
    const router = useRouter();
    const id = router.currentRoute.value.params.id as string;

    return {
      navigate: router.push,
      id,
    };
  },
});
