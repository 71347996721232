import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate({ name: 'OverviewPersonal', params: { id: _ctx.id } }))),
      class: _normalizeClass({ active: _ctx.active == 'data' })
    }, "Skillübersicht", 2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate({ name: 'OverviewPersonalGrading', params: { id: _ctx.id } }))),
      class: _normalizeClass({ active: _ctx.active == 'grading' })
    }, " Mitarbeiter:innengespräch ", 2)
  ]))
}