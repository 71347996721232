
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import GradingForm from '@/views/app/management/_GradingForm.vue';
import TableTabs from '@/components/TableTabs.vue';

export default defineComponent({
  components: {
    GradingForm,
    TableTabs,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const name = ref('');

    const id = computed(() => parseInt(router.currentRoute.value.params.id.toString()));
    store.dispatch('fetchUserData', id.value).then((res: any) => {
      name.value = `${res.firstname} ${res.lastname}`;
    });

    const saving = ref(false);

    const onSave = (user: any) => {
      if (!saving.value) {
        saving.value = true;
        store.dispatch('updateWorker', { id: id.value, user }).then((res: any) => {
          store.dispatch('addNotification', { message: 'Mitarbeiter gespeichert' });
          saving.value = false;
        });
      }
    };

    return {
      id,
      name,
      onSave,
    };
  },
});
