import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headline = _resolveComponent("Headline")!
  const _component_TableTabs = _resolveComponent("TableTabs")!
  const _component_GradingForm = _resolveComponent("GradingForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Headline, {
      h1: "Skillübersicht",
      h2: `von ${_ctx.name}`
    }, null, 8, ["h2"]),
    _createVNode(_component_TableTabs, { active: "grading" }),
    _createVNode(_component_GradingForm, {
      onSave: _ctx.onSave,
      userid: _ctx.id
    }, null, 8, ["onSave", "userid"])
  ]))
}